import React from 'react';

interface Partner {
  link?: {
    href?: string;
  };
  image?: {
    href?: string;
  };
  title?: string;
}

interface BoxContent {
  meta: {
    partners: Partner[];
  };
}

interface Box {
  content: BoxContent[];
}

interface PartnersProps {
  boxes: Box[];
}

const Partners: React.FC<PartnersProps> = ({ boxes }) => {
  let partners: Partner[] = boxes
    .map(box => box.content[0].meta.partners)
    .flat()
    .filter(Boolean);

  partners = [...new Map(partners.map(item => [item.link?.href, item])).values()];

  return (
    <div className="cell grid-x padding-1">
      <div className="cell large-12 text-center">
        <h2 className="vrt-edubox__title vrt-edubox__title--light margin-top-1">Partners</h2>
        <div className="grid-x align-bottom">
          {partners.map((partner, index) => (
            <div className="cell large-4 text-center" key={index}>
              <a className="vrt-edubox__link" target="_blank" rel="noopener noreferrer" href={partner.link?.href}>
                <img src={partner.image?.href} alt={partner.title} className="css-grey" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;